import React, { memo } from 'react';

import styles from './styles.module.css';
import { ContentWrapper } from '../content-wrapper';
import iconInsta from '../../assets/icon-insta.svg';
import iconFb from '../../assets/icon-fb.svg';
import iconEmail from '../../assets/icon-email.svg';
import iconPhone from '../../assets/icon-phone.svg';
import { TextWithIcon } from '../text-with-icon';

const ManagerBlockComponent = () => {
    return (
        <div className={styles.managerBlock}>
            <ContentWrapper>
                <div className={styles.managerBlock__content}>
                    <div className={styles.managerBlock__contact}>
                        <div className={styles.managerBlock__contactTitle}>
                            Мархасин <br />
                            Антон Борисович
                        </div>
                        <div className={styles.managerBlock__contactDescription}>
                            Управляющий лаборатории
                        </div>
                        <div className={styles.managerBlock__contactPhone}>
                            <a href="tel:+7 (921) 932-52-64">
                                <TextWithIcon
                                    invert
                                    text="+7 (921) 932-52-64"
                                    iconSrc={iconPhone}
                                />
                            </a>
                        </div>
                    </div>

                    <div className={styles.managerBlock__contentTitle}>
                        Старшие <br className={styles.mobile} /> зубные техники:
                    </div>

                    <div className={styles.managerBlock__contacts}>
                        <div className={styles.managerBlock__contact}>
                            <div>
                                <div className={styles.managerBlock__contactTitle_small}>
                                    Евтушенко Вадим
                                </div>
                                <div className={styles.managerBlock__contactDescription__small}>
                                    Отделение каркасов и <br/> комбинированного съемного <br/> протезирования
                                </div>
                            </div>
                            <div className={styles.managerBlock__contactPhone}>
                                <a href="tel:+7 (911) 019-91-11">
                                    <TextWithIcon
                                        invert
                                        text="+7 (911) 019-91-11"
                                        iconSrc={iconPhone}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className={styles.managerBlock__contact}>
                            <div>
                                <div className={styles.managerBlock__contactTitle_small}>
                                    Агаськин Александр
                                </div>
                                <div className={styles.managerBlock__contactDescription__small}>
                                    Отделение керамики и <br/> временного протезирования
                                </div>
                            </div>
                            <div className={styles.managerBlock__contactPhone}>
                                <a href="tel:+7 (931) 366-06-46">
                                    <TextWithIcon
                                        invert
                                        text="+7 (931) 366-06-46"
                                        iconSrc={iconPhone}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className={styles.managerBlock__contact}>
                           <div>
                               <div className={styles.managerBlock__contactTitle_small}>
                                   Штейник Анастасия
                               </div>
                               <div className={styles.managerBlock__contactDescription__small}>
                                   Отделение съемного <br/> протезирования
                               </div>
                           </div>
                            <div className={styles.managerBlock__contactPhone}>
                                <a href="tel:+7 (931) 299-74-16">
                                    <TextWithIcon
                                        invert
                                        text="+7 (931) 299-74-16"
                                        iconSrc={iconPhone}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className={styles.managerBlock__contacts_phones}>
                        <div className={styles.managerBlock__phones}>
                            <div className={styles.managerBlock__phone}>
                                <a href="tel:+7 (812) 449-30-01">
                                    <TextWithIcon
                                        invert
                                        text="+7 (812) 449-30-01"
                                        iconSrc={iconPhone}
                                    />
                                </a>
                            </div>
                            <div className={styles.managerBlock__phone}>
                                <a href="tel:+7 (812) 456-70-73">
                                    <TextWithIcon
                                        invert
                                        text="+7 (812) 456-70-73"
                                        iconSrc={iconPhone}
                                    />
                                </a>
                            </div>
                        </div>
                        <div className={styles.managerBlock__socials}>
                            <a href="https://instagram.com/1zublab" target="_blank">
                                <img
                                    className={styles.managerBlock__social}
                                    src={iconInsta}
                                    alt=""
                                />
                            </a>
                            <a href="https://mobile.facebook.com/1zublab/" target="_blank">
                                <img
                                    className={styles.managerBlock__social}
                                    src={iconFb}
                                    alt=""
                                />
                            </a>
                            <a href="mailto:1zublab@mail.ru">
                                <img
                                    className={styles.managerBlock__social}
                                    src={iconEmail}
                                    alt=""
                                />
                            </a>
                            <a href="https://wa.me/89995274028">
                                <img
                                    className={styles.managerBlock__social}
                                    src={iconPhone}
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const ManagerBlock = memo(ManagerBlockComponent);
